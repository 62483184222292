import {routes as defaultRoutes} from '@salesforce/retail-react-app/app/routes'

import React, {useEffect} from 'react'
import loadable from '@loadable/component'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {withRouter} from 'react-router-dom'

// Components
import {Skeleton} from '@chakra-ui/react'
import {configureRoutes} from '@salesforce/retail-react-app/app/utils/routes-utils'

// Others
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

const fallback = <Skeleton height="75vh" width="100%" />

// Pages
const PageNotFound = loadable(
    () => import('@salesforce/retail-react-app/app/pages/page-not-found'),
    {fallback}
)

// Remove SFRA/SiteGenesis routes from PWA Kit
const ecomRoutes = ['/checkout', '*']

const hybridRoutes = [
    ...defaultRoutes.filter((route) => !ecomRoutes.includes(route.path)),
    {
        path: '*',
        component: withRouter((props) => {
            const config = getConfig()
            const {location} = props
            const urlParams = new URLSearchParams(location.search)
            const {site} = useMultiSite()
            const siteId = site && site.id ? site.id : config?.app?.defaultSite

            if (typeof window !== 'undefined') {
                useEffect(() => {
                    const newURL = new URL(window.location)
                    if (!urlParams.has('redirected')) {
                        newURL.searchParams.append('redirected', '1')
                        newURL.pathname = `/s/${siteId}/${window.location.pathname
                            .split('/')
                            .slice(2)
                            .join('/')}`
                        window.location.replace(newURL)
                    }
                }, [window.location.href])
            }

            if (urlParams.has('redirected')) {
                return <PageNotFound {...props} />
            }
            return null
        })
    }
]

export default () => {
    const config = getConfig()
    // Only use these routes if we are in hybrid mode otherwise use defaults
    // This is driven via the config and env variables
    const routes = config.app.enableHybrid ? hybridRoutes : defaultRoutes
    return configureRoutes(routes, config, {
        ignoredRoutes: ['/callback', '*']
    })
}
