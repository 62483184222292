import React from 'react'
import {routes as defaultRoutes} from '@salesforce/retail-react-app/app/routes'

import loadable from '@loadable/component'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

import {Skeleton} from '@chakra-ui/react'
import {configureRoutes} from '@salesforce/retail-react-app/app/utils/routes-utils'

const fallback = <Skeleton height="75vh" width="100%" />

const OrderStatus = loadable(() => import('@salesforce/retail-react-app/app/pages/order-status'), {
    fallback
})

// add our before the wildcard route
export const routes = defaultRoutes
routes.splice(-1, 0, {
    path: '/order-status',
    component: OrderStatus,
    exact: true
})

export default () => {
    const config = getConfig()
    return configureRoutes(routes, config, {
        ignoredRoutes: ['/callback', '*']
    })
}
