import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Heading, Button, Box, Text, Grid, GridItem, extendTheme} from '@chakra-ui/react'
import {ChakraProvider} from '@chakra-ui/react'
import theme from '../../../theme'
import tinycolor from 'tinycolor2'
import {PageDesignerThemeContext} from '@salesforce/retail-react-app/app/contexts/pd-theme-context'
import {PageDesignerRegion} from '../tree'

/**
 * Convert component data into chakra theme overriding base project theme
 *
 * @param component
 */
export function componentToChakraTheme(component, additionalThemeOverrides = {}) {
    const {primaryColor, secondaryColor, textColor, jsonTheme, footerFGColor, footerBGColor} =
        component.data
    var _jsonTheme = {}
    if (jsonTheme) {
        try {
            let jsonThemeCode = jsonTheme
            if (typeof jsonTheme === 'object') {
                jsonThemeCode = jsonTheme.result
            }
            let themefunc = new Function('theme', `return ${jsonThemeCode}`)
            _jsonTheme = themefunc(theme)
        } catch (e) {
            console.error('Unable to parse JSON theme: ' + e)
        }
    }

    // TODO: instead of overriding colors here try to be more surgical

    const _primary = new tinycolor(primaryColor)
    const primaryDark = _primary.darken(10).toString()
    return extendTheme(
        {
            colors: {
                primary: primaryColor,
                secondary: secondaryColor,
                blue: {
                    500: primaryColor,
                    600: primaryColor,
                    700: primaryDark
                },
                gray: {
                    900: textColor ? textColor : theme.colors.gray['900']
                }
            },
            components: {
                Footer: {
                    baseStyle: {
                        container: {
                            background: footerBGColor
                                ? footerBGColor
                                : secondaryColor
                                ? secondaryColor
                                : 'gray.900'
                        },
                        content: {
                            color: footerFGColor ? footerFGColor : 'white'
                        }
                    }
                }
            }
        },
        additionalThemeOverrides,
        _jsonTheme,
        theme
    )
}

export function ColorPalette({name, color}) {
    // const theme = useTheme()
    return (
        <Flex align="center">
            <Box borderRadius="md" boxSize="3rem" boxShadow="inner" mr={3} bgColor={color} />
            <Box fontSize="sm">
                <Box fontWeight="semibold" textTransform="capitalize">
                    {name}
                </Box>
                <Box>{color}</Box>
            </Box>
        </Flex>
    )
}

ColorPalette.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string
}

// This component is mainly intended to visualize the theme in page designer
// the theme page is used elsewhere to provide the theme information to all components
export default function HeadlessTheme({component}) {
    const {primaryColor, secondaryColor, textColor, logo} = component.data
    Object.assign(logo, component.custom.logo)
    const addonsRegion = component.regions[0]

    const siteTheme = componentToChakraTheme(component)

    return (
        <PageDesignerThemeContext.Provider
            value={{
                logo: logo.disBaseLink,
                siteTheme
            }}
        >
            <ChakraProvider theme={siteTheme}>
                <Box padding="8">
                    <Heading fontSize="4xl" marginBottom="8" textAlign="center">
                        Composable Storefront Theme
                    </Heading>
                    <Box maxW="800" marginBottom="4">
                        <Text mb="2">This is your site theme. This is plain text.</Text>
                        <Text mb="2">
                            Paneer red leicester fondue. Danish fontina mascarpone cheese and wine
                            gouda say cheese rubber cheese parmesan everyone loves. Airedale cheesy
                            feet cow fromage gouda cheese and biscuits taleggio paneer. Monterey
                            jack pecorino taleggio roquefort gouda taleggio edam.
                        </Text>
                    </Box>

                    <Heading fontSize="3xl" marginBottom="4">
                        Logo
                    </Heading>
                    <Box maxW={'200'}>
                        <img src={logo.disBaseLink} alt="logo" />
                    </Box>

                    <Heading fontSize="3xl" marginBottom="4">
                        Theme Colors
                    </Heading>

                    <Box maxW="800" marginBottom="4">
                        <Grid
                            mt={7}
                            gap={6}
                            templateColumns="repeat( auto-fit, minmax(200px, 1fr) )"
                        >
                            <ColorPalette name="Primary Color" color={primaryColor} />
                            <ColorPalette name="Secondary Color" color={secondaryColor} />
                            <ColorPalette
                                name="Text Color"
                                color={textColor ? textColor : theme.colors.gray['900']}
                            />
                        </Grid>
                    </Box>

                    <Heading fontSize="3xl" marginBottom="4">
                        Application Colors
                    </Heading>

                    <Heading fontSize="2xl" marginBottom="4">
                        Header
                    </Heading>
                    <Box maxW="800" marginBottom="4"></Box>
                    <Heading fontSize="2xl" marginBottom="4">
                        Footer
                    </Heading>

                    <Box maxW="800" marginBottom="4">
                        <Grid
                            mt={7}
                            gap={6}
                            templateColumns="repeat( auto-fit, minmax(200px, 1fr) )"
                        >
                            <ColorPalette
                                name="Footer FG Color"
                                color={siteTheme.components.Footer.baseStyle.content.color}
                            />
                            <ColorPalette
                                name="Footer BG Color"
                                color={siteTheme.components.Footer.baseStyle.container.background}
                            />
                        </Grid>
                    </Box>

                    <Heading fontSize="3xl" marginBottom="4">
                        Buttons
                    </Heading>

                    <Box maxW="800" marginBottom="4">
                        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                            <GridItem w="100%" h="10">
                                <Button variant="solid">Solid</Button>
                            </GridItem>
                            <GridItem w="100%" h="10">
                                <Button variant="outline">Outline</Button>
                            </GridItem>
                            <GridItem w="100%" h="10">
                                <Button variant="footer">Footer</Button>
                            </GridItem>
                            <GridItem w="100%" h="10">
                                <Button variant="link" colorScheme="red">
                                    Link (Red)
                                </Button>
                            </GridItem>
                            <GridItem w="100%" h="10">
                                <Button variant="link">Link (Blue)</Button>
                            </GridItem>
                        </Grid>
                    </Box>

                    <Box mb="8" mt="8">
                        <Heading fontSize="3xl" marginBottom="4">
                            Addons
                        </Heading>

                        <PageDesignerRegion region={addonsRegion} wrapper={Box} mb="4" />
                    </Box>
                </Box>
            </ChakraProvider>
        </PageDesignerThemeContext.Provider>
    )
}

HeadlessTheme.propTypes = {
    component: PropTypes.object
}
