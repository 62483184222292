import React from 'react'
import {routes as defaultRoutes} from '@salesforce/retail-react-app/app/routes'

import loadable from '@loadable/component'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

import {Skeleton} from '@chakra-ui/react'
import {configureRoutes} from '@salesforce/retail-react-app/app/utils/routes-utils'

const fallback = <Skeleton height="75vh" width="100%" />

const PasswordlessLogin = loadable(
    () => import('@salesforce/retail-react-app/app/pages/passwordless-login'),
    {fallback}
)

export const routes = defaultRoutes
routes.splice(-1, 0, {
    path: '/passwordless-login',
    component: PasswordlessLogin,
    exact: true
})

export default () => {
    const config = getConfig()
    console.log(routes)
    return configureRoutes(routes, config, {
        ignoredRoutes: ['/callback', '*']
    })
}
