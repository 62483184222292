import React, {useContext} from 'react'
import {Box, Grid, Heading, ListItem, OrderedList} from '@chakra-ui/react'
import {ColorPalette} from '@salesforce/retail-react-app/app/components/page-designer/headless/headlessTheme'
import {PageDesignerThemeContext} from '@salesforce/retail-react-app/app/contexts/pd-theme-context'
import PropTypes from 'prop-types'

/**
 * This component is only used in page designer; the actual component data is used in the
 * theme context to render the ServiceChatBot component
 *
 * @constructor
 */
export default function PDEinsteinChatBot({component}) {
    const {einsteinChatBotBackground, einsteinChatBotForeground} = component.data
    var {siteTheme} = useContext(PageDesignerThemeContext)
    return (
        <Box>
            <Heading fontSize="2xl" marginBottom="4">
                Einstein Chat Bot
            </Heading>

            <Box maxW="800" marginBottom="4">
                <Grid mt={7} gap={6} templateColumns="repeat( auto-fit, minmax(200px, 1fr) )">
                    <ColorPalette
                        name="Foreground Color"
                        color={einsteinChatBotForeground ?? 'white'}
                    />
                    <ColorPalette
                        name="Background Color"
                        color={einsteinChatBotBackground ?? siteTheme.colors.primary}
                    />
                </Grid>
            </Box>
            <Heading fontSize="1xl" marginBottom="4">
                Additional Setup Instructions
            </Heading>

            <Box ml="8">
                <OrderedList>
                    <ListItem>
                        Under <code>Setup -&gt; CSP Trusted Sites</code> add a new trusted site with{' '}
                        <code>https://*.mobify-storefront.com</code> as the URL
                    </ListItem>
                    <ListItem>
                        Under <code>Setup -&gt; CORS</code> add the same{' '}
                        <code>https://*.mobify-storefront.com</code> as an allowed site
                    </ListItem>
                </OrderedList>
            </Box>
        </Box>
    )
}

PDEinsteinChatBot.propTypes = {
    component: PropTypes.object
}
