import React from 'react'
import {Box, Heading} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import RecommendedProducts from '@salesforce/retail-react-app/app/components/recommended-products'
import PDProductScroller from '@salesforce/retail-react-app/app/components/page-designer/headless/productScroller'

/**
 * This component is only used in page designer; the actual component data is used in the
 * theme context to supply the recommender overrides throughout the app
 *
 * @constructor
 */
export default function PDRecommenderOverrides({component}) {
    const {recommenderOverrides} = component.data
    return (
        <Box>
            <Heading fontSize="2xl" marginBottom="4">
                Recommender Overrides
            </Heading>

            {recommenderOverrides && (
                <>
                    {recommenderOverrides.overrides.map((override) => (
                        <>
                            <Heading fontSize="xl" marginBottom="4">
                                {override.source} ({override.type}){' '}
                                {override.recommender ? `(Target: ${override.recommender})` : ''}
                            </Heading>
                            {override.type === 'Recommender' && (
                                <RecommendedProducts
                                    title={override.title || override.source}
                                    recommender={override.recommender}
                                />
                            )}
                            {override.type === 'Product' && (
                                <PDProductScroller
                                    component={{
                                        data: {
                                            products: override.products,
                                            heading: override.title || override.source
                                        }
                                    }}
                                />
                            )}
                        </>
                    ))}
                </>
            )}
        </Box>
    )
}

PDRecommenderOverrides.propTypes = {
    component: PropTypes.object
}
