import React from 'react'
import PropTypes from 'prop-types'
import {Carousel as ReactResponsiveCarousel} from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
//import './carousel-overrides.css'

/**
 * Wraps react-responsive-carousel; immediate children are slides
 *
 * @param children
 * @constructor
 */
export default function Carousel({children, ...props}) {
    // const styles = useMultiStyleConfig('Carousel')
    return (
        <ReactResponsiveCarousel {...props} showThumbs={false}>
            {children}
        </ReactResponsiveCarousel>
    )
}

Carousel.propTypes = {
    children: PropTypes.node
}
