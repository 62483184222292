import {default as BaseRecommendedProducts} from '@salesforce/retail-react-app/app/components/recommended-products'

import React from 'react'
import {PageDesignerThemeContext} from '@salesforce/retail-react-app/app/contexts/pd-theme-context'
import ProductScroller from '@salesforce/retail-react-app/app/components/product-scroller'
import {useProducts} from '@salesforce/commerce-sdk-react'
import PropTypes from 'prop-types'

function OverrideProductScroller({productIds, ...props}) {
    const {data: _products, isLoading} = useProducts(
        {parameters: {ids: productIds}},
        {
            select: (data) => {
                // The downstream components ProductScroller and ProductTile are expecting einstein
                // and "product hit" shaped objects; so we need to transform
                return data?.data?.map((product) => ({
                    ...product,
                    productId: product.id,
                    image: product?.imageGroups?.[0]?.images?.[0],
                    hitType: 'product'
                }))
            }
        }
    )

    return (
        <ProductScroller
            {...props}
            products={_products}
            isLoading={isLoading}
            productTileProps={() => ({
                enableFavourite: false
            })}
        />
    )
}

OverrideProductScroller.propTypes = {
    productIds: PropTypes.array
}

export default function RecommendedProducts(props) {
    const {recommender} = props
    const themeContext = React.useContext(PageDesignerThemeContext)
    const overridesAddon = themeContext.addons?.find(
        (a) => a.typeId === 'headless.recommenderOverrides'
    )

    const overrides = overridesAddon?.data?.recommenderOverrides?.overrides ?? []
    const override = overrides.find((o) => o.source === recommender)

    if (override && override.type === 'Disabled') {
        // do nothing
        return null
    } else if (override && override.type === 'Recommender') {
        return (
            <BaseRecommendedProducts
                {...props}
                recommender={override.recommender}
                title={override.title || props.title}
            />
        )
    } else if (override && override.type === 'Product') {
        return (
            <OverrideProductScroller
                productIds={override.products}
                {...props}
                title={override.title || props.title}
            />
        )
    } else {
        // return original if no overrides
        return <BaseRecommendedProducts {...props} />
    }
}

RecommendedProducts.propTypes = BaseRecommendedProducts.propTypes
