import React, {useEffect, useContext, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useStyleConfig} from '@chakra-ui/react'
import {PageDesignerThemeContext} from '@salesforce/retail-react-app/app/contexts/pd-theme-context'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'
import useSitePreferences from '@salesforce/retail-react-app/app/hooks/use-site-preferences'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import {EINSTEIN_BOTS} from '@salesforce/retail-react-app/app/constants'
import '/overlays/einstein-chatbot/app/components/service/index.css'

const onClient = typeof window !== 'undefined'
const SCRIPT_TYPES = {
    MIAW: 'miaw',
    EMBEDDED_CHAT: 'embeddedChat',
    UNKNOWN: 'unknown'
}
const SCRIPT_IDS = {
    PRECHAT_FIELDS: 'setPrechatFields'
}

const parseMIAW = (einsteinChatBotScript) => {
    // Extract the URL of the script
    const urlRegex = /src='(.*?)'/i
    const urlMatch = einsteinChatBotScript.match(urlRegex)
    const srcUrl = urlMatch && urlMatch.length > 1 ? urlMatch[1] : null

    // Extract the onload function name
    const onloadRegex = /onload='(.*?)'/i
    const onloadMatch = einsteinChatBotScript.match(onloadRegex)
    const onloadFunctionName =
        onloadMatch && onloadMatch.length > 1 ? onloadMatch[1].replace('()', '') : null

    // Extract the function using the onload function name
    const functionRegex = new RegExp(`function ${onloadFunctionName}\\(\\) \\{[\\s\\S]*?\\};`, 'i')
    const functionMatch = einsteinChatBotScript.match(functionRegex)
    let innerHTML = functionMatch ? functionMatch[0] : null

    if (innerHTML) {
        innerHTML += `
        function embeddedLogout() {
            try {
                if (embeddedservice_bootstrap && embeddedservice_bootstrap.userVerificationAPI) {
                    embeddedservice_bootstrap.userVerificationAPI.clearSession();
                }
            } catch (err) {
                console.error('Error clearing embedded messaging session', err);
            }
        }`
    }

    return {
        srcUrl,
        onloadFunctionName,
        innerHTML
    }
}

const parseEmbeddedChat = (einsteinChatBotScript) => {
    // Extract the URL of the script
    const urlRegex = /src=["'](.*?)["']/i
    const urlMatch = einsteinChatBotScript.match(urlRegex)
    const srcUrl = urlMatch && urlMatch.length > 1 ? urlMatch[1] : null

    // Remove script tags and style tags with their content from the innerHTML
    const innerHTML = einsteinChatBotScript
        .replace(/<script[^>]*>|<\/script>/gi, '')
        .replace(/<style type='text\/css'>[\s\S]*?<\/style>/gi, '')

    return {
        srcUrl,
        innerHTML
    }
}

const getScriptType = (einsteinChatBotScript) => {
    if (!einsteinChatBotScript) return SCRIPT_TYPES.UNKNOWN
    if (einsteinChatBotScript.includes('initESW')) return SCRIPT_TYPES.EMBEDDED_CHAT
    if (einsteinChatBotScript.includes('initEmbeddedMessaging')) return SCRIPT_TYPES.MIAW
    return SCRIPT_TYPES.UNKNOWN
}

const parseChatBotScript = (einsteinChatBotScript) => {
    const scriptType = getScriptType(einsteinChatBotScript)
    let parsedScript = {scriptType}
    switch (scriptType) {
        case SCRIPT_TYPES.MIAW:
            parsedScript = {...parsedScript, ...parseMIAW(einsteinChatBotScript)}
            break
        case SCRIPT_TYPES.EMBEDDED_CHAT:
            parsedScript = {
                ...parsedScript,
                ...parseEmbeddedChat(einsteinChatBotScript)
            }
            break
        default:
            break
    }
    return parsedScript
}

const useEmbeddedMessaging = () => {
    const {data: sitePrefResult} = useSitePreferences({
        action: '/',
        ids: [
            EINSTEIN_BOTS.SITE_PREFS.ENABLE_MIAW_EMBEDDED_MESSAGING,
            EINSTEIN_BOTS.SITE_PREFS.MIAW_CODE_SNIPPET,
            EINSTEIN_BOTS.SITE_PREFS.EMBEDDED_CHAT_CODE_SNIPPET,
            EINSTEIN_BOTS.SITE_PREFS.MIAW_HIDDEN_PRECHAT_FIELDS
        ]
    })

    const enableMiawMessaging =
        sitePrefResult?.[EINSTEIN_BOTS.SITE_PREFS.ENABLE_MIAW_EMBEDDED_MESSAGING]?.value || false
    const miawCodeSnippet =
        sitePrefResult?.[EINSTEIN_BOTS.SITE_PREFS.MIAW_CODE_SNIPPET]?.value?.source || null
    const embeddedChatCodeSnippet =
        sitePrefResult?.[EINSTEIN_BOTS.SITE_PREFS.EMBEDDED_CHAT_CODE_SNIPPET]?.value || null
    const miawHiddenPrechatFields =
        sitePrefResult?.[EINSTEIN_BOTS.SITE_PREFS.MIAW_HIDDEN_PRECHAT_FIELDS]?.value || null

    return {
        enableMiawMessaging,
        miawCodeSnippet,
        embeddedChatCodeSnippet,
        miawHiddenPrechatFields
    }
}

const removeExistingScript = (id) => {
    if (!id) return
    let script = document.getElementById(id)
    if (script) {
        script.remove()
    }
}

const includeEmbeddedChat = ({parsedScript, siteTheme, styles}) => {
    const {srcUrl, innerHTML} = parsedScript

    const script = document.createElement('script')
    const style = document.createElement('style')
    const code = document.createElement('script')

    script.src = srcUrl || 'https://service.force.com/embeddedservice/5.0/esw.min.js'
    style.innerText = CHAT_BOT_STYLES(
        styles.color,
        styles.backgroundColor ?? siteTheme.colors.primary
    )
    code.innerHTML = innerHTML
    script.onload = () => {
        document.body.appendChild(code)
    }
    document.head.appendChild(style)
    document.body.appendChild(script)
    return () => {
        document.head.removeChild(style)
        document.body.removeChild(script)
        code && document.body.removeChild(code)
    }
}

const includeMIAW = ({parsedScript}) => {
    const script = document.createElement('script')
    const code = document.createElement('script')
    const {srcUrl, innerHTML, onloadFunctionName} = parsedScript

    if (srcUrl && innerHTML && onloadFunctionName) {
        script.src = srcUrl
        code.innerHTML = innerHTML
        script.onload = () => {
            document.body.appendChild(code)
            if (typeof window[onloadFunctionName] === 'function') {
                window[onloadFunctionName]()
            }
        }
        document.body.appendChild(script)
    }

    return () => {
        script && document.body.removeChild(script)
        code && document.body.removeChild(code)
    }
}

const setPrechatFields = ({chatFields, siteId, miawHiddenPrechatFields}) => {
    const {firstName = '', lastName = '', email = '', subject = ''} = chatFields || {}

    let visiblePrechatFieldsHtml = ''
    let hiddenPrechatFieldsHtml = ''
    const visiblePrechatFields = {}
    if (subject) {
        visiblePrechatFields._subject = {
            value: subject,
            isEditableByEndUser: true
        }
    }
    if (firstName) {
        visiblePrechatFields._firstName = {
            value: firstName,
            isEditableByEndUser: false
        }
    }
    if (lastName) {
        visiblePrechatFields._lastName = {
            value: lastName,
            isEditableByEndUser: false
        }
    }
    if (email) {
        visiblePrechatFields._email = {
            value: email,
            isEditableByEndUser: false
        }
    }
    if (Object.keys(visiblePrechatFields).length > 0) {
        visiblePrechatFieldsHtml = `embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields(${JSON.stringify(
            visiblePrechatFields
        )})`
    }

    // Set hidden prechat fields
    var hiddenPrechatFields = {}
    if (miawHiddenPrechatFields) {
        try {
            hiddenPrechatFields = JSON.parse(miawHiddenPrechatFields)
        } catch (ex) {
            hiddenPrechatFields = {}
        }
    }
    if (siteId) {
        hiddenPrechatFields.siteId = siteId
    }
    if (Object.keys(hiddenPrechatFields).length) {
        hiddenPrechatFieldsHtml = `embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(${JSON.stringify(
            hiddenPrechatFields
        )})`
    }

    const innerHTML = `
        try {
            window.addEventListener("onEmbeddedMessagingReady", () => {
                console.log('onEmbeddedMessagingReady event');
                embeddedservice_bootstrap.settings.devMode = ${EINSTEIN_BOTS.ENABLE_DEBUG_MODE};
                embeddedservice_bootstrap.prechatAPI.unsetVisiblePrechatFields(['_firstName','_lastName','_email']);
                ${visiblePrechatFieldsHtml}
                ${hiddenPrechatFieldsHtml}
            });
        } catch (err) {
            console.error('Error setting embedded messaging prechat fields: ', err);
        }`

    removeExistingScript(SCRIPT_IDS.PRECHAT_FIELDS)
    const script = document.createElement('script')
    script.setAttribute('id', SCRIPT_IDS.PRECHAT_FIELDS)
    script.innerHTML = innerHTML
    document.body.appendChild(script)
    return () => {
        script && document.body.removeChild(script)
    }
}

const clearSession = () => {
    if (typeof window['embeddedLogout'] === 'function') {
        window['embeddedLogout']()
    }
}

const insertBotScript = ({siteTheme, styles, einsteinChatBotScript}) => {
    if (!onClient) return
    const parsedScript = parseChatBotScript(einsteinChatBotScript)
    if (parsedScript.scriptType === SCRIPT_TYPES.EMBEDDED_CHAT) {
        includeEmbeddedChat({parsedScript, siteTheme, styles})
    } else if (parsedScript.scriptType === SCRIPT_TYPES.MIAW) {
        includeMIAW({parsedScript})
    }
}

const useChatFields = (customer) => {
    const {isRegistered, firstName, lastName, email} = customer
    return useMemo(() => {
        if (isRegistered) {
            return {
                firstName: firstName || '',
                lastName: lastName || '',
                email: email || ''
            }
        }
        return {firstName: '', lastName: '', email: ''}
    }, [isRegistered, firstName, lastName, email])
}

export default function ServiceChatBot() {
    let {einsteinChatBotScript, siteTheme} = useContext(PageDesignerThemeContext)
    const {site} = useMultiSite()
    const intl = useIntl()
    const styles = useStyleConfig('ServiceChatBot')
    const {data: customer} = useCurrentCustomer()
    const {firstName, lastName, email} = useChatFields(customer)
    const {enableMiawMessaging, miawCodeSnippet, embeddedChatCodeSnippet, miawHiddenPrechatFields} =
        useEmbeddedMessaging()
    const subject = intl.formatMessage({
        id: 'chatbot.fields.subject',
        defaultMessage: 'Question on Northern Trail Outfitters'
    })
    const chatFields = {firstName, lastName, email, subject}

    // if no script was set in the theme, pull from site preferences
    if (!einsteinChatBotScript) {
        // use MIAW as default
        if (miawCodeSnippet && enableMiawMessaging) {
            einsteinChatBotScript = miawCodeSnippet
        } else if (embeddedChatCodeSnippet) {
            einsteinChatBotScript = embeddedChatCodeSnippet
        }
    }

    useEffect(() => {
        insertBotScript({
            siteTheme,
            styles,
            einsteinChatBotScript
        })
        if (onClient) {
            setPrechatFields({
                chatFields,
                miawHiddenPrechatFields,
                siteId: site.id
            })
        }
    }, [])

    useEffect(() => {
        if (onClient) {
            setPrechatFields({
                chatFields,
                miawHiddenPrechatFields,
                siteId: site.id
            })
            clearSession()
        }
    }, [firstName, lastName, email])

    return <></>
}

const CHAT_BOT_STYLES = function (foreground, background) {
    return `
.embeddedServiceHelpButton .helpButton .uiButton {
background-color: ${background} !important;
color: ${foreground} !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
outline: 1px solid ${background} !important;
}`
}
