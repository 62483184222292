import {default as BaseApp} from '@salesforce/retail-react-app/app/components/_app'

import React from 'react'
import PropTypes from 'prop-types'
import {Global} from '@emotion/react'

// Chakra
import {ChakraProvider} from '@chakra-ui/react'
import theme from '@salesforce/retail-react-app/app/theme'

import {componentToChakraTheme} from '@salesforce/retail-react-app/app/components/page-designer/headless/headlessTheme'
import {PageDesignerThemeContext} from '../../contexts/pd-theme-context'
import ServiceChatBot from '@salesforce/retail-react-app/app/components/service'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import {usePage} from '@salesforce/commerce-sdk-react'
import {Helmet} from 'react-helmet'

// TODO wrap most of this logic up in a HoC
const App = (props) => {
    var logo
    var customStyles
    var einsteinChatBotScript
    const {site} = useMultiSite()

    const {data: themePage} = usePage({parameters: {pageId: `pwa-theme-${site.id}`}})

    var siteTheme = theme
    if (themePage) {
        // theme page supports 1 region with 1 theme component
        let themeComponent = themePage?.regions?.[0]?.components[0]
        if (themeComponent) {
            var additionalTheme = {}
            logo = themeComponent.custom.logo.disBaseLink
            customStyles = themeComponent.data.customStyles

            var themeAddons = themeComponent.regions[0]?.components
            var addons = []
            if (themeAddons && themeAddons.length > 0) {
                var einsteinChatBotAddon = themeAddons.find(
                    (c) => c.typeId === 'headless.einsteinChatBot'
                )
                if (einsteinChatBotAddon) {
                    einsteinChatBotScript = einsteinChatBotAddon.data.einsteinChatBotScript
                    additionalTheme = Object.assign(additionalTheme, {
                        components: {
                            ServiceChatBot: {
                                baseStyle: {
                                    backgroundColor:
                                        einsteinChatBotAddon.data.einsteinChatBotBackground ??
                                        undefined,
                                    color:
                                        einsteinChatBotAddon.data.einsteinChatBotForeground ??
                                        undefined
                                }
                            }
                        }
                    })
                }

                // add all the addons to the addons array
                addons = themeAddons.map((c) => {
                    return {
                        typeId: c.typeId,
                        data: c.data
                    }
                })
            }

            siteTheme = componentToChakraTheme(themeComponent, additionalTheme)
        } else {
            console.error(
                'No theme component found on theme page; Check your headless theme page designer page'
            )
        }
    }

    return (
        <ChakraProvider theme={siteTheme}>
            <PageDesignerThemeContext.Provider
                value={{
                    logo,
                    siteTheme,
                    einsteinChatBotScript,
                    addons
                }}
            >
                {customStyles && <Global styles={customStyles} />}
                <BaseApp {...props}>
                    {props.children}
                    <ServiceChatBot />
                </BaseApp>
                <Helmet>
                    <meta name="referrer" content="origin" />
                </Helmet>
            </PageDesignerThemeContext.Provider>
        </ChakraProvider>
    )
}

App.propTypes = {
    children: PropTypes.node,
    targetLocale: PropTypes.string,
    messages: PropTypes.object,
    categories: PropTypes.object,
    config: PropTypes.object
}

export default App
