import {default as BaseApp} from '@salesforce/retail-react-app/app/components/_app'

import React from 'react'
import PropTypes from 'prop-types'
import StorefrontToolkitConnector from '@salesforce/retail-react-app/app/components/storefront-toolkit-connector'
import {useAccessToken} from '@salesforce/commerce-sdk-react'

const App = (props) => {
    const {getTokenWhenReady} = useAccessToken()

    return (
        <StorefrontToolkitConnector getToken={getTokenWhenReady}>
            <BaseApp {...props} />
        </StorefrontToolkitConnector>
    )
}

App.propTypes = {
    children: PropTypes.node,
    targetLocale: PropTypes.string,
    messages: PropTypes.object,
    categories: PropTypes.object,
    config: PropTypes.object
}

export default App
