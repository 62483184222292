import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {getParentOrigin} from '@salesforce/commerce-sdk-react/utils'

// Add hostnames that may be used for the storefront toolkit
const TOOLKIT_PREVIEW_ORIGINS = ['dx.commercecloud.salesforce.com', 'demandware.net']

const onClient = () => typeof window !== 'undefined'

/**
 * Provides communication with the storefront toolkit wrapper if the app is running
 * in that context. The toolkit wrapper will set shopper context on our behalf
 * based on the storefront toolkit preview functions
 *
 * @param getToken
 * @param children
 * @return {Element}
 * @constructor
 */
export default function StorefrontToolkitConnector({getToken, children}) {
    const [messageChannel, setMessageChannel] = useState(null)
    const origin = onClient() && getParentOrigin()
    const enabled = TOOLKIT_PREVIEW_ORIGINS.some((host) => origin && origin.includes(host))

    useEffect(() => {
        if (onClient() && enabled) {
            const channel = new MessageChannel()
            // post message to parent of this iframe
            window.parent.postMessage(
                {
                    type: 'app-ready',
                    payload: {}
                },
                origin,
                [channel.port2]
            )
            setMessageChannel(channel)
        }
    }, [])

    useEffect(() => {
        if (!messageChannel) {
            return
        }
        const onMessage = (e) => {
            console.log('Message received from parent', e.data)

            // message type
            const type = e.data?.type
            // message acknowledgement
            messageChannel.port1.postMessage({type: 'ack', payload: {originalType: type}})

            // We're not actually doing anything with incoming messages (yet)
        }
        messageChannel.port1.onmessage = onMessage

        async function sendToken() {
            console.log('Sending token to parent')
            const token = await getToken()
            messageChannel.port1.postMessage({type: 'token', payload: {token}})
        }

        sendToken()
    }, [messageChannel])

    return <>{children}</>
}

StorefrontToolkitConnector.propTypes = {
    children: PropTypes.node,
    getToken: PropTypes.func
}
