import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {PageDesignerContext} from '../support'
import {PageDesignerComponent, useRegionEditorData} from '../tree'
import Carousel from '../../carousel'
import {Skeleton} from '@chakra-ui/react'

export default function PDCarousel({component}) {
    const {editMode, preview} = useContext(PageDesignerContext)
    var attributes = {}
    if (editMode) {
        // ok to disable this as this is only true or false per page
        // eslint-disable-next-line react-hooks/rules-of-hooks
        attributes = useRegionEditorData(component.regions[0].id, editMode)
    }
    return (
        <div {...attributes}>
            {!component.regions[0] || !component.regions[0].components ? (
                <Skeleton height="200" />
            ) : (
                <Carousel
                    autoPlay={preview || !editMode}
                    infiniteLoop
                    showThumbs={false}
                    showStatus={true}
                >
                    {component.regions[0].components?.map((c) => (
                        <div key={c.id}>
                            <PageDesignerComponent component={c} />
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    )
}

PDCarousel.propTypes = {
    component: PropTypes.object
}
