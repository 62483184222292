import React from 'react'
import PropTypes from 'prop-types'
import ImageWithTitle from '@salesforce/retail-react-app/app/components/page-designer/headless/imageWithTitle'
import PDCarousel from '@salesforce/retail-react-app/app/components/page-designer/headless/carousel'
import PopularCategories from '@salesforce/retail-react-app/app/components/page-designer/headless/popularCategories'
import PopularCategory from '@salesforce/retail-react-app/app/components/page-designer/headless/popularCategory'
import HeadlessTheme from '@salesforce/retail-react-app/app/components/page-designer/headless/headlessTheme'
import ThreeColumn from '@salesforce/retail-react-app/app/components/page-designer/headless/layout/3column'
import PDBanner from '@salesforce/retail-react-app/app/components/page-designer/headless/banner'
import {Box} from '@chakra-ui/react'
import SectionHeader from '@salesforce/retail-react-app/app/components/page-designer/headless/sectionHeader'
import EinsteinProducts from '@salesforce/retail-react-app/app/components/page-designer/headless/einsteinProducts'
import ContentCard from '@salesforce/retail-react-app/app/components/page-designer/headless/contentCard'
import PDProductScroller from '@salesforce/retail-react-app/app/components/page-designer/headless/productScroller'
import PDEinsteinChatBot from '@salesforce/retail-react-app/app/components/page-designer/headless/einsteinChatBot'
import RawJSX from '@salesforce/retail-react-app/app/components/page-designer/headless/rawJSX'
import RawJSXAssisted from '@salesforce/retail-react-app/app/components/page-designer/headless/rawJSXAssisted'
import PDGrid from '@salesforce/retail-react-app/app/components/page-designer/headless/grid'
import PDImage from '@salesforce/retail-react-app/app/components/page-designer/headless/image'
import PDProductTile from '@salesforce/retail-react-app/app/components/page-designer/headless/productTile'
import PDVideoBanner from '@salesforce/retail-react-app/app/components/page-designer/headless/videoBanner'
import VideoContentCard from '@salesforce/retail-react-app/app/components/page-designer/headless/videoContentCard'
import PDRecommenderOverrides from '@salesforce/retail-react-app/app/components/page-designer/headless/recommenderOverrides'

// component registry
// these are the react implemented types of the PD components
// they should know how to render themselves and their children (i.e. regions)
export const COMPONENTS = {
    'headless.imageWithTitle': ImageWithTitle,
    'headless.banner': PDBanner,
    'headless.carousel': PDCarousel,
    'headless.popularCategories': PopularCategories,
    'headless.popularCategory': PopularCategory,
    'headless.theme': HeadlessTheme,
    'headless.3column': ThreeColumn,
    'headless.sectionHeader': SectionHeader,
    'headless.einsteinProducts': EinsteinProducts,
    'headless.productScroller': PDProductScroller,
    'headless.contentCard': ContentCard,
    'headless.einsteinChatBot': PDEinsteinChatBot,
    'headless.recommenderOverrides': PDRecommenderOverrides,
    'headless.rawJSX': RawJSX,
    'headless.openaiRawJSX': RawJSX,
    'headless.grid': PDGrid,
    'headless.image': PDImage,
    'headless.productTile': PDProductTile,
    'headless.videoBanner': PDVideoBanner,
    'headless.videoContentCard': VideoContentCard,
    'headless.einsteinAssisted': RawJSXAssisted
}

export const DebugContext = React.createContext({
    debug: false,
    setDebug: () => {}
})

/**
 * Page Designer Context provides runtime information about the state of the PD page
 * in page designer or headless context
 */
export const PageDesignerContext = React.createContext({
    // debug component tree
    debug: false,
    // is PD preview? (can we know this?)
    preview: false,
    // is in PD edit mode?
    editMode: false
})

/**
 * Component context provides runtime information about the nearst component parent
 * @type {React.Context<{component: null}>}
 */
export const ComponentContext = React.createContext({
    component: null
})

export class PDErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {hasError: false}
        this.type = props.type
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, error: error}
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Box color="white" fontSize="large" backgroundColor="red" style={{padding: '50px'}}>
                    {this.type} could not be initialized. Be sure to set required fields.
                    <br />
                    <pre>{this.state.error ? this.state.error.message : 'Unknown error'}</pre>
                </Box>
            )
        }

        return this.props.children
    }

    static propTypes = {
        type: PropTypes.string,
        children: PropTypes.node
    }
}
