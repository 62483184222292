import React, {useContext} from 'react'
import {
    Box,
    Image,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {PageDesignerThemeContext} from '@salesforce/retail-react-app/app/contexts/pd-theme-context'
import {BrandLogo as BrandLogoIcon} from '@salesforce/retail-react-app/app/components/icons'

// re-export everything but our new BrandLogo
export * from '*'

export const BrandLogo = function BrandLogo(props) {
    const themeContext = useContext(PageDesignerThemeContext)
    const styles = useMultiStyleConfig('Header')

    return (
        <>
            {themeContext && themeContext.logo ? (
                <Box __css={styles.themeLogoContainer}>
                    <Image src={themeContext.logo} />
                </Box>
            ) : (
                <BrandLogoIcon {...props} />
            )}
        </>
    )
}
