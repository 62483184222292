import React from 'react'
import {routes as defaultRoutes} from '@salesforce/retail-react-app/app/routes'

import loadable from '@loadable/component'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

import {Skeleton} from '@chakra-ui/react'
import {configureRoutes} from '@salesforce/retail-react-app/app/utils/routes-utils'

const fallback = <Skeleton height="75vh" width="100%" />

const Page = loadable(() => import('./pages/page'), {fallback})
const PageViewer = loadable(() => import('@salesforce/retail-react-app/app/pages/page-viewer'), {
    fallback
})

// add our before the wildcard route
export const routes = defaultRoutes
routes.splice(
    -1,
    0,
    {
        path: '/page/:pageId',
        component: Page
    },
    {
        path: '/page-viewer/:pageId',
        component: PageViewer
    }
)

export default () => {
    const config = getConfig()
    return configureRoutes(routes, config, {
        ignoredRoutes: ['/callback', '*']
    })
}
