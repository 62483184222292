export default {
    parts: ['wrapper', 'container', 'heading'],
    baseStyle: {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 8
        },
        heading: {
            fontSize: '2xl',
            fontWeight: 'bold',
            mb: 8
        },
        container: {}
    }
}
